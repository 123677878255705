







































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Badge from '@/components/atoms/Badge.vue'
import { TMedicalInterviewData } from '@/store/Conditions'

@Component({ components: { Badge } })
export default class extends Vue {
  $fixApp!: (fixed: boolean) => void
  @Prop() data?: TMedicalInterviewData
  isOpen = false

  close(): void {
    this.isOpen = false
    this.$fixApp(false)
  }
  open(): void {
    this.isOpen = true
    this.$fixApp(true)
  }
  commutingList = {
    1: '車',
    2: '自転車',
    3: 'バス',
    4: '電車',
    5: '徒歩',
  }
}
