











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: false }) active!: boolean
  @Prop({ default: false }) isDesc!: boolean
}
