






































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'

export type SelectOption = {
  id: number | string
  name: string
}

@Component({ components: { Multiselect } })
export default class extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ required: true }) sortKey!: string
  @Prop({
    default: () => [
      { id: 'asc', name: '昇順' },
      { id: 'desc', name: '降順' },
    ],
  })
  sortOptions!: SelectOption[]
  @Prop({ default: () => [] }) filterOptions!: SelectOption[]
  @Prop({ default: false }) active!: boolean
  @Prop({ default: '540' }) size!: string

  isOpen = false
  selectedSortOption: SelectOption = { id: 'asc', name: '昇順' }
  selectedFilterOptions: SelectOption[] = []
  selectedAll = true

  get sortType(): string {
    return this.selectedSortOption ? `${this.selectedSortOption.id}` : ''
  }
  get filterValues(): (string | number)[] {
    return this.selectedFilterOptions.map((v) => v.id)
  }

  close(): void {
    this.isOpen = false
  }

  open(): void {
    this.isOpen = true
    if (this.selectedAll) {
      this.selectedFilterOptions = [...this.filterOptions]
    }
    if (!this.active) {
      this.selectedSortOption = this.sortOptions[0]
    }
  }

  switchAllSelected(): void {
    this.selectedFilterOptions = this.selectedAll ? [...this.filterOptions] : []
  }

  select(): void {
    this.selectedAll = this.filterValues.length === this.filterOptions.length - 1
  }

  remove(): void {
    this.selectedAll = false
  }

  cancel(): void {
    this.close()
    this.$emit('cancel')
  }

  submit(): void {
    this.close()
    this.$emit('submit', this.sortKey, this.sortType, this.filterValues)
  }
}
