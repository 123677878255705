




import { Component, Vue, Prop } from 'vue-property-decorator'
import Chart, { ChartConfiguration } from 'chart.js'

@Component({})
export default class extends Vue {
  @Prop() chartConfig!: ChartConfiguration
  chart?: Chart

  updated(): void {
    if (this.chart) {
      this.chart.destroy()
    }
    const canvas = this.$el as HTMLCanvasElement
    const chartCanvas = canvas.getContext('2d') as CanvasRenderingContext2D
    this.chart = new Chart(chartCanvas, this.chartConfig)
  }
}
