import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import dayjs, { Dayjs } from '@/libs/dayjs'
import axios, { AxiosError } from 'axios'
import _ from 'lodash'
import config from '@/config'

export const ErrorMsg = {
  responseError: (status: number) => 'API response error status:' + status,
  OverMaxCountCsvError: '出力件数が多すぎます。期間を短くして再度出力してください。',
  BusyCsvDownloadProcessError: '現在アクセスが集中しています。しばらくしてから再度出力してください。',
  FailedToDownloadCsv: 'CSV出力に失敗しました。通信環境を確認してください。',
} as const

const HomeConst = {
  DefaultPage: 1,
  DefaultPageSize: 50,
} as const

export const GraphDateScope = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
} as const
export type GraphDateScopeType = typeof GraphDateScope[keyof typeof GraphDateScope]

/** 症状の型 */
export type TSymptom = {
  name: string
  onset: boolean
}

/** グループの型 */
export type TGroup = {
  id: string
  name: string
}

/** ユーザーの型 */
export type TUser = {
  id: string
  firstName: string
  lastName: string
  groups: TGroup[]
}

/** 問診票データの型 */
export type TMedicalInterviewData = {
  fever: { flag: boolean; temperature: string; averageTemperature: string }
  symptoms: TSymptom[]
  commuting: { means: number; detail: string; other: boolean }
  hospital: string
  pcr: { flag: boolean; detail: string }
  accompany: { flag: boolean; person: string }
  travel: { flag: boolean; person: string; place: string; traveldatefrom: string; traveldateto: string }
  gathering: { flag: boolean; person: string; place: string; traveldatefrom: string; traveldateto: string }
  workday: Dayjs
}

/** 体温入力記録の型 */
export type TCondition = {
  readonly isBlank: boolean
  body_temp: number
  with_contact_notification: boolean
  medical_interview?: {
    id: string
    medical_interview_data: TMedicalInterviewData
  }
  message: string
  date: Dayjs
  symptoms: TSymptom[]
  user: TUser
  organizationQuestionAnswers?: string[]
}

export type TConditionMeta = {
  page: number
  lastPage: number
}

export type HomeCsv = {
  userId: string
  userName: string
  registrationDatetime: Dayjs
  bodyTemp: number
  message: string
  joinedSymptoms: string
}

export type GroupCsv = {
  bodyTemp: number
  message: string
  date: Dayjs
  symptoms: TSymptom[]
  user: {
    id: string
    firstName: string
    lastName: string
  }
}

@Module({ dynamic: true, namespaced: true, name: 'conditions', store })
class Mod extends VuexModule {
  conditions: TCondition[] = []
  meta: TConditionMeta = { page: 1, lastPage: 1 }
  csvData: TCondition[] = []
  homeCsvData: HomeCsv[] = []
  groupCsvData: GroupCsv[] = []
  graphDateScope: GraphDateScopeType = GraphDateScope.WEEK

  @Mutation
  setConditions(conditions: TCondition[]): void {
    this.conditions = conditions
  }

  @Mutation
  setCsvData(conditions: TCondition[]): void {
    this.csvData = conditions
  }

  @Mutation
  setMeta(meta: TConditionMeta): void {
    this.meta = meta
  }

  @Mutation
  setHomeCsv(homeCsvData: HomeCsv[]): void {
    this.homeCsvData = homeCsvData
  }

  @Mutation
  setGroupCsv(groupCsvData: GroupCsv[]): void {
    this.groupCsvData = groupCsvData
  }

  @Mutation
  setGraphDateScope(scope: GraphDateScopeType): void {
    this.graphDateScope = scope
  }

  /** 体温入力記録のデータを読み込む */
  @Action
  async fetch(data: {
    date: Dayjs
    groupId?: string
    page?: number
    pageSize?: number
    sortKey?: string
    sortType?: string
    filterValues?: (number | string)[]
  }): Promise<void> {
    const page = data.page ?? HomeConst.DefaultPage
    const pageSize = data.pageSize ?? HomeConst.DefaultPageSize
    const url = `${config.enterprise.apiUrl}/users/condition/${data.date.format('YYYYMMDD')}`
    const res = await axios.get(url, {
      params: {
        group_id: data.groupId,
        page,
        pageSize,
        sortKey: data.sortKey,
        sortType: data.sortType,
        filterValues: data.filterValues,
      },
    })
    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    this.setConditions(mapConditions(res.data.conditions))

    this.setMeta(res.data.meta)
  }

  /** Group向け体温入力記録のデータを読み込む */
  @Action({ commit: 'setConditions' })
  async fetchByGroup(data: {
    date: Dayjs
    groupId: string
    sortKey?: string
    sortType?: string
    filterValues?: (number | string)[]
  }): Promise<TCondition[]> {
    const url = `${config.enterprise.apiUrl}/groups/${data.groupId}/users`
    const { data: conditions, status } = await axios({
      method: 'get',
      url: encodeURI(url),
      params: {
        conditionDate: data.date.format('YYYYMMDD'),
        sortKey: data.sortKey,
        sortType: data.sortType,
        filterValues: data.filterValues,
      },
    })
    if (status !== 200) {
      throw 'API response error status:' + status
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return conditions.map((condition: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const medicalInterview = condition?.medicalInterview?.medical_interview_data
      return {
        user: condition.user,
        isBlank: condition.isBlank,
        body_temp: parseFloat(condition.bodyTemp),
        with_contact_notification: condition.hasContactNotification,
        message: condition.message,
        date: dayjs(condition.date),
        symptoms:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          condition.symptoms?.map((symptom: any) => ({
            name: symptom.name,
            onset: symptom.onset,
          })) || [],
        medical_interview: medicalInterview
          ? {
              id: condition.medicalInterview.id,
              medical_interview_data: {
                fever: {
                  flag: _.head(medicalInterview.fever.flag) || false,
                  temperature: medicalInterview.fever.temperature,
                  averageTemperature: medicalInterview.fever.averageTemperature,
                },
                symptoms:
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  condition.symptoms?.map((symptom: any) => ({
                    name: symptom.name,
                    onset: symptom.onset,
                  })) || [],
                commuting: {
                  means: _.head(medicalInterview.commuting.means) || 0,
                  detail: medicalInterview.commuting.detail,
                  other: _.head(medicalInterview.commuting.other) || false,
                },
                hospital: medicalInterview.hospital,
                pcr: {
                  flag: _.head(medicalInterview.pcr.flag) || false,
                  detail: medicalInterview.pcr.detail,
                },
                accompany: {
                  flag: _.head(medicalInterview.accompany.flag) || false,
                  person: medicalInterview.accompany.person,
                },
                travel: {
                  flag: _.head(medicalInterview.travel.flag) || false,
                  person: medicalInterview.travel.person,
                  place: medicalInterview.travel.place,
                  traveldatefrom: medicalInterview.travel.traveldatefrom,
                  traveldateto: medicalInterview.travel.traveldateto,
                },
                gathering: {
                  flag: _.head(medicalInterview.gathering.flag) || false,
                  person: medicalInterview.gathering.person,
                  place: medicalInterview.gathering.place,
                  traveldatefrom: medicalInterview.gathering.traveldatefrom,
                  traveldateto: medicalInterview.gathering.traveldateto,
                },
                workday: dayjs(medicalInterview.workday),
              },
            }
          : undefined,
        organization_question_answers: condition.organization_questions?.map(
          (question: { answer: string }) => question.answer
        ),
      }
    })
  }

  /** Person用体温入力記録のデータを読み込む */
  @Action({ commit: 'setConditions' })
  async fetchForPerson(data: {
    startDate: Dayjs
    endDate: Dayjs
    userId: string
    sortKey?: string
    sortType?: string
    filterValues?: (number | string)[]
  }): Promise<TCondition[]> {
    const url = `/users/condition/person/${data.startDate.format('YYYYMMDD')}/${data.endDate.format('YYYYMMDD')}`
    const res = await axios.get(url, {
      params: {
        user_id: data.userId,
        sortKey: data.sortKey,
        sortType: data.sortType,
        filterValues: data.filterValues,
      },
    })
    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return _.map(res.data.conditions, (condition: any): TCondition => {
      const medicalInterview = condition.medical_interview?.medical_interview_data
      return {
        user: {
          id: condition.user.id,
          firstName: condition.user.firstName,
          lastName: condition.user.lastName,
          groups: [],
        },
        isBlank: condition.isBlank,
        body_temp: parseFloat(condition.body_temp),
        date: dayjs(condition.date),
        with_contact_notification: condition.with_contact_notification,
        message: condition.message,
        symptoms:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          condition.symptoms?.map((symptom: any) => ({
            name: symptom.name,
            onset: symptom.onset,
          })) || [],
        medical_interview: medicalInterview
          ? {
              id: condition.medical_interview.id,
              medical_interview_data: {
                fever: {
                  flag: _.head(medicalInterview.fever.flag) || false,
                  temperature: medicalInterview.fever.temperature,
                  averageTemperature: medicalInterview.fever.averageTemperature,
                },
                symptoms:
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  condition.symptoms?.map((symptom: any) => ({
                    name: symptom.name,
                    onset: symptom.onset,
                  })) || [],
                commuting: {
                  means: _.head(medicalInterview.commuting.means) || 0,
                  detail: medicalInterview.commuting.detail,
                  other: _.head(medicalInterview.commuting.other) || false,
                },
                hospital: medicalInterview.hospital,
                pcr: {
                  flag: _.head(medicalInterview.pcr.flag) || false,
                  detail: medicalInterview.pcr.detail,
                },
                accompany: {
                  flag: _.head(medicalInterview.accompany.flag) || false,
                  person: medicalInterview.accompany.person,
                },
                travel: {
                  flag: _.head(medicalInterview.travel.flag) || false,
                  person: medicalInterview.travel.person,
                  place: medicalInterview.travel.place,
                  traveldatefrom: medicalInterview.travel.traveldatefrom,
                  traveldateto: medicalInterview.travel.traveldateto,
                },
                gathering: {
                  flag: _.head(medicalInterview.gathering.flag) || false,
                  person: medicalInterview.gathering.person,
                  place: medicalInterview.gathering.place,
                  traveldatefrom: medicalInterview.gathering.traveldatefrom,
                  traveldateto: medicalInterview.gathering.traveldateto,
                },
                workday: dayjs(medicalInterview.workday),
              },
            }
          : undefined,
        organizationQuestionAnswers: condition.organization_questions?.map(
          (question: { answer: string }) => question.answer
        ),
      }
    })
  }

  /** CSV用の体温入力記録のデータを読み込む */
  @Action({ commit: 'setCsvData' })
  async fetchByRange({
    startDate,
    endDate,
    userIds,
  }: {
    startDate: Dayjs
    endDate: Dayjs
    userIds?: string[]
  }): Promise<TCondition[]> {
    const url = `${config.enterprise.apiUrl}/csv/person/${startDate.format('YYYYMMDD')}/${endDate.format('YYYYMMDD')}`
    const res = await axios(url, {
      params: {
        userIds,
      },
    })
    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    return mapConditions(res.data, false)
  }

  /** グループ単位でCSV用の体温入力記録のデータを読み込む */
  @Action({ commit: 'setGroupCsv' })
  async fetchByGroupIdAndRange({
    startDate,
    endDate,
    groupId,
  }: {
    startDate: Dayjs
    endDate: Dayjs
    groupId: string
  }): Promise<GroupCsv[]> {
    const url = `${config.enterprise.apiUrl}/csv/group/${startDate.format('YYYYMMDD')}/${endDate.format('YYYYMMDD')}`
    const res = await axios(url, {
      params: {
        groupId,
      },
    })
    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    return mapGroupCsv(res.data)
  }

  @Action({ commit: 'setHomeCsv', rawError: true })
  async fetchHomeCsv({ startDate, endDate }: { startDate: Dayjs; endDate: Dayjs }): Promise<HomeCsv[]> {
    try {
      const res = await axios(
        `${config.enterprise.apiUrl}/csv/home/${startDate.format('YYYYMMDD')}/${endDate.format('YYYYMMDD')}`
      )
      return res.data
    } catch (e) {
      throw ErrorMsg.responseError((e as AxiosError).response?.status || 500)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapGroupCsv(responseData: any): GroupCsv[] {
  return _.map(responseData, (data) => {
    return {
      bodyTemp: parseFloat(data.bodyTemp),
      message: data.message,
      date: dayjs(data.date),
      symptoms: _.map(data.symptoms || [], (symptom) => ({
        name: symptom.name,
        onset: symptom.onset,
      })),
      user: {
        id: data.user?.id || '',
        firstName: data.user?.firstName || '',
        lastName: data.user?.lastName || '',
      },
    }
  })
}

/** レスポンスデータをマッピング */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapConditions(responseData: any, defaultIsBlank: boolean | null = null): TCondition[] {
  return _.map(
    responseData,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const medicalInterview = data.medical_interview?.medical_interview_data
      return {
        isBlank: defaultIsBlank === null ? !!data.isBlank : defaultIsBlank,
        body_temp: parseFloat(data.body_temp),
        with_contact_notification: data.with_contact_notification,
        medical_interview: medicalInterview
          ? {
              id: data.medical_interview.id,
              medical_interview_data: {
                fever: {
                  flag: _.head(medicalInterview.fever.flag) || false,
                  temperature: medicalInterview.fever.temperature,
                  averageTemperature: medicalInterview.fever.averageTemperature,
                },
                symptoms:
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  _.map(data.symptoms || [], (symptom: any) => ({
                    name: symptom.name,
                    onset: symptom.onset,
                  })),
                commuting: {
                  means: _.head(medicalInterview.commuting.means) || 0,
                  detail: medicalInterview.commuting.detail,
                  other: _.head(medicalInterview.commuting.other) || false,
                },
                hospital: medicalInterview.hospital,
                pcr: {
                  flag: _.head(medicalInterview.pcr.flag) || false,
                  detail: medicalInterview.pcr.detail,
                },
                accompany: {
                  flag: _.head(medicalInterview.accompany.flag) || false,
                  person: medicalInterview.accompany.person,
                },
                travel: {
                  flag: _.head(medicalInterview.travel.flag) || false,
                  person: medicalInterview.travel.person,
                  place: medicalInterview.travel.place,
                  traveldatefrom: medicalInterview.travel.traveldatefrom,
                  traveldateto: medicalInterview.travel.traveldateto,
                },
                gathering: {
                  flag: _.head(medicalInterview.gathering.flag) || false,
                  person: medicalInterview.gathering.person,
                  place: medicalInterview.gathering.place,
                  traveldatefrom: medicalInterview.gathering.traveldatefrom,
                  traveldateto: medicalInterview.gathering.traveldateto,
                },
                workday: dayjs(medicalInterview.workday),
              },
            }
          : undefined,
        message: data.message,
        date: dayjs(data.date),
        symptoms:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          _.map(data.symptoms || [], (symptom: any) => ({
            name: symptom.name,
            onset: symptom.onset,
          })),
        user: {
          id: data.user?.id || '',
          firstName: data.user?.firstName || '',
          lastName: data.user?.lastName || '',
          groups:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            _.map(data.user.groups || [], (group: any) => ({
              id: group.id,
              name: group.name,
            })),
        },
        organization_question_answers: data.organization_questions?.map(
          (question: { answer: string }) => question.answer
        ),
      }
    }
  )
}

export default getModule(Mod)
