import store from '@/store'
import { Module, VuexModule, getModule } from 'vuex-module-decorators'

export type WithMessage = {
  id: boolean
  name: string
}

type SortOption = {
  id: string
  name: string
}

@Module({ dynamic: true, namespaced: true, name: 'withMessages', store })
class Mod extends VuexModule {
  withMessages: WithMessage[] = [
    { id: true, name: 'メッセージあり' },
    { id: false, name: 'メッセージなし' },
  ]
  sortOptions: SortOption[] = [
    { id: 'asc', name: 'メッセージなし => メッセージあり' },
    { id: 'desc', name: 'メッセージあり => メッセージなし' },
  ]
}

export default getModule(Mod)
