




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class extends Vue {
  @Prop() bodyTemp?: number
  get iconClass(): string {
    const bodyTemp = this.bodyTemp || 0
    const warning = bodyTemp >= 37 && bodyTemp < 37.5
    const danger = bodyTemp >= 37.5
    if (danger) {
      return 'danger-icon'
    } else if (warning) {
      return 'warn-icon'
    } else {
      return 'info-icon'
    }
  }
}
