import store from '@/store'
import { Module, VuexModule, getModule } from 'vuex-module-decorators'

type SortOption = {
  id: string
  name: string
}

@Module({ dynamic: true, namespaced: true, name: 'bodyTemps', store })
class Mod extends VuexModule {
  sortOptions: SortOption[] = [
    { id: 'asc', name: '低い => 高い' },
    { id: 'desc', name: '高い => 低い' },
  ]
}

export default getModule(Mod)
