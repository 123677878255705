import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import axios from 'axios'
import config from '@/config'

export const ErrorMsg = {
  ResponseError: (status: number) => 'API response error status:' + status,
  FetchSymptomFailureError: '症状取得に失敗しました',
} as const

export type Symptom = {
  id: string
  name: string
}

type SortOption = {
  id: string
  name: string
}

@Module({ dynamic: true, namespaced: true, name: 'symptoms', store })
class Mod extends VuexModule {
  symptoms: Symptom[] = []
  sortOptions: SortOption[] = [
    { id: 'asc', name: '無症状 => 有症状' },
    { id: 'desc', name: '有症状 => 無症状' },
  ]

  @Mutation
  setSymptoms(symptoms: Symptom[]): void {
    this.symptoms = symptoms
  }

  @Action({ commit: 'setSymptoms', rawError: true })
  async fetch(): Promise<Symptom[]> {
    const url = `${config.enterprise.apiUrl}/symptoms`
    try {
      const res = await axios.get(url)
      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchSymptomFailureError)
    }
  }
}

export default getModule(Mod)
